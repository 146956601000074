import { type FC, useState, Fragment, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useForm, yupResolver } from '@mantine/form';
import { getNetworkError } from 'common/utils';
import { Button, Checkbox, DatePicker, IconButton, Input } from 'ui';

import { noop } from 'core/utils';
import { SETTINGS } from 'core/constants';
import { ErrorToast, SuccessToast } from 'components/toasts';
import {
  type ScalingPricingSubscriptionItem,
  ScalingPricingSubscriptionItemType,
  SubscriptionBillingInterval,
  SubscriptionPlan,
  SubscriptionPricingModel,
  type Invite,
} from 'core/types';
import { useInterval } from 'usehooks-ts';
import { RadioGroup } from 'components/inputs';
import {
  PLAN_BILLING_PERIOD_LABELS,
  PLAN_LABELS,
  PRICING_MODEL_LABELS,
  SCALING_PRICING_DEFAULT_FIXED_PRICES,
  SCALING_PRICING_DEFAULT_USAGE_PRICES,
  SCALING_PRICING_ITEM_LABELS,
} from 'core/constants/subscriptions';
import { SubscriptionItemConfig } from '../subscription-item-config';
import { addDays } from 'date-fns';

const API_URL = `${SETTINGS.apiUrl}/onboarding`;

const POLLING_INTERVAL_IN_MS = 5000;

const BILLING_INTERVAL_OPTIONS = Object.entries(PLAN_BILLING_PERIOD_LABELS).map(([key, label]) => ({
  label,
  value: key,
}));
const PLAN_OPTIONS = Object.entries(PLAN_LABELS).map(([key, label]) => ({ label, value: key }));
const PRICING_MODEL_OPTIONS = Object.entries(PRICING_MODEL_LABELS).map(([key, label]) => ({ label, value: key }));

type OnboardCustomerModalProps = {
  open: boolean;
  onClose: () => void;
};

type FormValues = {
  name: string;
  sendInviteEmail: boolean;
  subscription: {
    databaseStartingSize: string;
    plan: SubscriptionPlan;
    interval: SubscriptionBillingInterval;
    pricingModel: SubscriptionPricingModel;
    startDate?: Date;
    items: Record<ScalingPricingSubscriptionItemType, ScalingPricingSubscriptionItem>;
  };
  pointsOfContact: {
    pointOfContact: {
      name: string;
      email: string;
    };
    billingContact: {
      name: string;
      email: string;
    };
    buyer: {
      name: string;
      email: string;
    };
  };
};

const priceFieldValidation = yup.number().when('enabled', {
  is: true,
  then: (schema) =>
    schema.min(0, 'Must not be negative').integer('Must be a whole number').required('Price is required.'),
  otherwise: (schema) => schema.notRequired(),
});

const getNullableNumberFieldValidation = (conditionField: string, condition: any): yup.Schema => {
  return yup
    .number()
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : null)) // Convert empty string to null
    .when(conditionField, {
      is: condition,
      then: (schema) =>
        schema
          .typeError('Invalid number')
          .positive('Must be a positive number')
          .integer('Must be a whole number')
          .required('Field is required.'),
      otherwise: (schema) => schema.notRequired(),
    });
};

const formValidation = yup.object({
  name: yup.string().min(2, 'Invalid name.').required('Customer name is required.'),
  subscription: yup.object({
    plan: yup.string().required('Plan is required.'),
    interval: yup.string().required('Billing interval is required.'),
    databaseStartingSize: getNullableNumberFieldValidation('pricingModel', SubscriptionPricingModel.SCALING),
    startDate: yup.date().nullable(),
    items: yup.object({
      [ScalingPricingSubscriptionItemType.FUNDRAISING_ESSENTIALS_SUITE]: yup.object({
        enabled: yup.boolean().required(),
        yearlyBasePrice: priceFieldValidation,
        monthlyUsagePrice: priceFieldValidation,
      }),
      [ScalingPricingSubscriptionItemType.AUTOMATED_CULTIVATION]: yup.object({
        enabled: yup.boolean().required(),
        yearlyBasePrice: priceFieldValidation,
        monthlyUsagePrice: priceFieldValidation,
      }),
      [ScalingPricingSubscriptionItemType.DONOR_ACQUISITION]: yup.object({
        enabled: yup.boolean().required(),
        yearlyBasePrice: priceFieldValidation,
      }),
      [ScalingPricingSubscriptionItemType.FUNDRAISING_SUPPORT_SPECIALIST]: yup.object({
        enabled: yup.boolean().required(),
        yearlyBasePrice: priceFieldValidation,
        monthlyUsagePrice: priceFieldValidation,
        contactFloor: getNullableNumberFieldValidation('enabled', true),
      }),
    }),
  }),
  pointsOfContact: yup.object({
    pointOfContact: yup.object({
      name: yup.string().min(2, 'Invalid name.').required('Name is required.'),
      email: yup.string().email('Invalid email.').required('Email is required.'),
    }),
    billingContact: yup.object({
      name: yup.string().min(0, 'Invalid name.'),
      email: yup.string().email('Invalid email.'),
    }),
    buyer: yup.object({
      name: yup.string().min(0, 'Invalid name.'),
      email: yup.string().email('Invalid email.'),
    }),
  }),
});

const initialValues: FormValues = {
  name: '',
  sendInviteEmail: false,
  subscription: {
    databaseStartingSize: '',
    plan: SubscriptionPlan.FULL_SERVICE,
    interval: SubscriptionBillingInterval.YEARLY,
    pricingModel: SubscriptionPricingModel.SCALING,
    items: {
      [ScalingPricingSubscriptionItemType.FUNDRAISING_ESSENTIALS_SUITE]: {
        enabled: true,
        yearlyBasePrice: 0,
        monthlyUsagePrice: SCALING_PRICING_DEFAULT_USAGE_PRICES.FUNDRAISING_ESSENTIALS_SUITE,
      },
      [ScalingPricingSubscriptionItemType.AUTOMATED_CULTIVATION]: {
        enabled: false,
        yearlyBasePrice: 0,
        monthlyUsagePrice: SCALING_PRICING_DEFAULT_USAGE_PRICES.AUTOMATED_CULTIVATION,
      },
      [ScalingPricingSubscriptionItemType.DONOR_ACQUISITION]: {
        enabled: false,
        yearlyBasePrice: 300000,
      },
      [ScalingPricingSubscriptionItemType.FUNDRAISING_SUPPORT_SPECIALIST]: {
        enabled: false,
        yearlyBasePrice: 0,
        monthlyUsagePrice: SCALING_PRICING_DEFAULT_USAGE_PRICES.FUNDRAISING_SUPPORT_SPECIALIST,
        contactFloor: '20000',
      },
    },
  },
  pointsOfContact: {
    pointOfContact: {
      name: '',
      email: '',
    },
    billingContact: {
      name: '',
      email: '',
    },
    buyer: {
      name: '',
      email: '',
    },
  },
};

const CreateInviteModal: FC<OnboardCustomerModalProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [tenantId, setTenantId] = useState('');

  const form = useForm<FormValues>({
    initialValues,
    validate: yupResolver(formValidation),
    validateInputOnBlur: true,
  });

  const isScalingPricing = form.values.subscription.pricingModel === SubscriptionPricingModel.SCALING;

  const handleClose = useCallback((): void => {
    form.reset();
    setLoading(false);
    setTenantId('');
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const getOnboardState = useCallback(async () => {
    if (!tenantId) return;
    try {
      const {
        data: { status },
      } = await axios.get(`${API_URL}?tenantId=${tenantId}`);

      if (status === 'SUCCEEDED') {
        toast.custom((t) => <SuccessToast visible={t.visible} message="Invite created successfully!" />, {
          id: 'handleCreateInviteSuccess',
        });
        handleClose();
      } else if (status !== 'RUNNING') {
        throw new Error('Something went wrong, please try again!');
      }
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'handleCreateInviteError',
      });
      setLoading(false);
      setTenantId('');
    }
  }, [handleClose, tenantId]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useInterval(getOnboardState, tenantId ? POLLING_INTERVAL_IN_MS : null);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    setLoading(true);

    try {
      const { databaseStartingSize, items, plan, startDate, ...subscription } = values.subscription;
      const { data: invite }: { data: Invite } = await axios.post(`${API_URL}`, {
        ...values,
        subscription: {
          ...subscription,
          ...(isScalingPricing ? { databaseStartingSize, items } : { plan }),
          ...(startDate ? { startDate: Math.round(startDate.getTime() / 1000) } : {}),
        },
        email: values.pointsOfContact.pointOfContact.email,
        createInvite: true,
        sandbox: false,
      });

      setTenantId(invite.tenantId);
    } catch (err) {
      toast.custom((t) => <ErrorToast visible={t.visible} message={getNetworkError(err)} />, {
        id: 'handleCreateInviteError',
      });
      setLoading(false);
    }
  };

  const handleCalculateDefaultPrices = (databaseStartingSize: number): void => {
    for (const [itemType, usagePrice] of Object.entries(SCALING_PRICING_DEFAULT_USAGE_PRICES) as Array<
      [ScalingPricingSubscriptionItemType, number]
    >) {
      const fixedPrice = SCALING_PRICING_DEFAULT_FIXED_PRICES[itemType] || 0;
      const contactFloor = Number(form.values.subscription.items[itemType].contactFloor);

      if (!contactFloor) {
        const price = ((databaseStartingSize / 5000) * usagePrice + fixedPrice) * 12;
        form.setFieldValue(`subscription.items.${itemType}.yearlyBasePrice`, price);
        continue;
      }

      // Usage pricing does not start for products that have a contact floor until the contact floor is reached
      if (databaseStartingSize <= contactFloor) {
        form.setFieldValue(`subscription.items.${itemType}.yearlyBasePrice`, fixedPrice * 12);
        continue;
      }

      const price = (((databaseStartingSize - contactFloor) / 5000) * usagePrice + fixedPrice) * 12;
      form.setFieldValue(`subscription.items.${itemType}.yearlyBasePrice`, price);
    }
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={loading ? noop : handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-950/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-7xl overflow-hidden rounded-2xl bg-white-100 p-7 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h2" className="mt-2 text-center text-xl font-medium leading-6 text-gray-900">
                  Create Invite
                </Dialog.Title>

                <IconButton
                  Icon={<XMarkIcon className="size-5" />}
                  srOnly="Close"
                  onClick={handleClose}
                  className="absolute right-3 top-5 p-0.5"
                  color="transparent"
                  disabled={loading}
                />

                <div className="relative mt-8">
                  {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                  <form noValidate onSubmit={form.onSubmit(handleSubmit)} className="space-y-4">
                    <div className="grid grid-cols-8 gap-6">
                      <div className="col-span-3 space-y-6">
                        <Input
                          id="name"
                          label="Organization Name"
                          placeholder="Organization Name"
                          disabled={loading}
                          {...form.getInputProps('name')}
                          required
                          error={!!form.errors.name}
                          helperText={(form.errors.name as string) || ''}
                          size="xl"
                        />

                        <RadioGroup
                          legend="Billing Interval"
                          options={BILLING_INTERVAL_OPTIONS}
                          value={form.values.subscription.interval}
                          onChange={(e) => {
                            form.setFieldValue('subscription.interval', e.target.value as SubscriptionBillingInterval);
                          }}
                          disabled={loading}
                        />

                        <RadioGroup
                          legend="Pricing Model"
                          options={PRICING_MODEL_OPTIONS}
                          value={form.values.subscription.pricingModel}
                          onChange={(e) => {
                            form.setFieldValue('subscription.pricingModel', e.target.value as SubscriptionPricingModel);
                          }}
                          disabled={loading}
                        />

                        {isScalingPricing ? (
                          <Input
                            id="database-starting-size"
                            label="Database Starting Size"
                            placeholder="Database Starting Size"
                            type="number"
                            min="0"
                            step="5000"
                            disabled={loading}
                            {...form.getInputProps('subscription.databaseStartingSize')}
                            onChange={(e) => {
                              form.setFieldValue('subscription.databaseStartingSize', e.target.value);
                              handleCalculateDefaultPrices(Number(e.target.value));
                            }}
                            required
                            error={!!form.errors['subscription.databaseStartingSize']}
                            helperText={form.errors['subscription.databaseStartingSize'] as string}
                            size="xl"
                          />
                        ) : (
                          <RadioGroup
                            legend="Plan"
                            options={PLAN_OPTIONS}
                            value={form.values.subscription.plan}
                            onChange={(e) => {
                              form.setFieldValue('subscription.plan', e.target.value as SubscriptionPlan);
                            }}
                            disabled={loading}
                          />
                        )}

                        <div className="flex items-center space-x-3">
                          <DatePicker
                            label="Subscription Start Date (Optional)"
                            value={form.values.subscription.startDate}
                            {...form.getInputProps('subscription.startDate')}
                            fromDate={addDays(new Date(), 1)}
                            containerClassName="w-full"
                          />
                        </div>
                      </div>
                      <div className="col-span-5 grid auto-rows-max grid-cols-2 gap-6">
                        <Input
                          id="pointOfContact-name"
                          label="Point of Contact Name"
                          placeholder="Point of Contact Name"
                          disabled={loading}
                          {...form.getInputProps('pointsOfContact.pointOfContact.name')}
                          required
                          error={!!form.errors['pointsOfContact.pointOfContact.name']}
                          helperText={
                            (form.errors['pointsOfContact.pointOfContact.name'] as string) ||
                            'Receives app login invite'
                          }
                          size="xl"
                        />

                        <div>
                          <Input
                            id="pointOfContact-email"
                            label="Point of Contact Email"
                            placeholder="Point of Contact Email"
                            disabled={loading}
                            {...form.getInputProps('pointsOfContact.pointOfContact.email')}
                            required
                            error={!!form.errors['pointsOfContact.pointOfContact.email']}
                            helperText={(form.errors['pointsOfContact.pointOfContact.email'] as string) || ''}
                            size="xl"
                            autoComplete="off"
                            containerClassName="mb-2"
                          />

                          <Checkbox
                            id="sendInviteEmail"
                            label="Send Invite Email"
                            disabled={loading}
                            {...form.getInputProps('sendInviteEmail', { type: 'checkbox' })}
                          />
                        </div>

                        <Input
                          id="billingContact-name"
                          label="Billing Contact Name"
                          placeholder="Billing Contact Name"
                          disabled={loading}
                          {...form.getInputProps('pointsOfContact.billingContact.name')}
                          error={!!form.errors['pointsOfContact.billingContact.name']}
                          helperText={
                            (form.errors['pointsOfContact.billingContact.name'] as string) ||
                            'Receives invoice and payment link'
                          }
                          size="xl"
                        />

                        <Input
                          id="billingContact-email"
                          label="Billing Contact Email"
                          placeholder="Billing Email"
                          disabled={loading}
                          {...form.getInputProps('pointsOfContact.billingContact.email')}
                          error={!!form.errors['pointsOfContact.billingContact.email']}
                          helperText={(form.errors['pointsOfContact.billingContact.email'] as string) || ''}
                          size="xl"
                          autoComplete="off"
                        />

                        <Input
                          id="buyer-name"
                          label="Buyer Name"
                          placeholder="Buyer Name"
                          disabled={loading}
                          {...form.getInputProps('pointsOfContact.buyer.name')}
                          error={!!form.errors['pointsOfContact.buyer.name']}
                          helperText={
                            (form.errors['pointsOfContact.buyer.name'] as string) ||
                            'Currently just for record-keeping purposes \nMay receive notifications in the future'
                          }
                          size="xl"
                          containerClassName="whitespace-pre"
                        />

                        <Input
                          id="buyer-email"
                          label="Buyer Email"
                          placeholder="Buyer Email"
                          disabled={loading}
                          {...form.getInputProps('pointsOfContact.buyer.email')}
                          error={!!form.errors['pointsOfContact.buyer.email']}
                          helperText={(form.errors['pointsOfContact.buyer.email'] as string) || ''}
                          size="xl"
                          autoComplete="off"
                        />
                      </div>

                      {isScalingPricing && (
                        <div className="col-span-4 space-y-4">
                          {Object.entries(form.values.subscription.items).map(([key, item]) => (
                            <SubscriptionItemConfig
                              key={key}
                              label={SCALING_PRICING_ITEM_LABELS[key as ScalingPricingSubscriptionItemType]}
                              id={key}
                              disabled={loading}
                              optional={key !== ScalingPricingSubscriptionItemType.FUNDRAISING_ESSENTIALS_SUITE}
                              item={item}
                              onChange={(itemKey, value) => {
                                form.setFieldValue(`subscription.items.${key}.${itemKey}`, value);
                              }}
                              errors={{
                                yearlyBasePrice: form.errors[`subscription.items.${key}.yearlyBasePrice`],
                                monthlyUsagePrice: form.errors[`subscription.items.${key}.monthlyUsagePrice`],
                                contactFloor: form.errors[`subscription.items.${key}.contactFloor`],
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="flex w-full items-center justify-between">
                      <Button title="Confirm" color="primary" type="submit" disabled={loading} loading={loading} />

                      <Button title="Cancel" onClick={handleClose} disabled={loading} />
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateInviteModal;
